<template>
  <BaseFiltersSideBar
    v-model="model"
    @filters-submit="onFiltersSubmit"
  >
    <OrderProductsListFiltersForm
      ref="order-products-list-filters-form"
      v-model="filters"
      @submit="onFiltersSubmit"
    />
  </BaseFiltersSideBar>
</template>

<script>
import BaseFiltersSideBar from '@/components/ui/sidebar/BaseFiltersSideBar.vue'
import OrderProductsListFiltersForm from '@/components/orders-products/form/OrderProductsListForm.vue'

export default {
  name: 'OrderProductsListFilters',
  components: {
    OrderProductsListFiltersForm,
    BaseFiltersSideBar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filters: {},
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    clearFilters() {
      this.$refs['order-products-list-filters-form'].clearForm()
    },
    onFiltersSubmit() {
      this.model = false
      this.$emit('filters-submit', this.filters)
    },
  },
}
</script>