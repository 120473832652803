var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('submit', _vm.model)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('submit', _vm.model)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',[_vm._v(" Fecha entre ")]),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-datepicker',{staticClass:"margin-right-datepicker",attrs:{"value":_vm.model.date_min,"date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"size":"sm","reset-button":"","hide-header":true,"placeholder":"Desde","label-help":null,"max":_vm.model.date_max,"start-weekday":"1"},on:{"input":function($event){return _vm.update('date_min', $event)}}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-datepicker',{staticClass:"margin-left-datepicker",attrs:{"value":_vm.model.date_max,"date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"size":"sm","reset-button":"","hide-header":true,"placeholder":"Hasta","label-help":null,"min":_vm.model.date_min,"start-weekday":"1"},on:{"input":function($event){return _vm.update('date_max', $event)}}})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Grupo de categorías","label-for":"categoríes"}},[_c('BaseSelect',{attrs:{"id":"categoríes","value":_vm.model.category_group,"resource":_vm.$data.$constants.RESOURCES.RESOURCE_CATEGORY_GROUPS},on:{"input":function($event){return _vm.update('category_group', $event)}}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Categoría","label-for":"product-category"}},[_c('CategorySelect',{attrs:{"value":_vm.model.product_category},on:{"input":function($event){return _vm.update('product_category', $event)}}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Productos","label-for":"product"}},[_c('BaseSelect',{attrs:{"id":"product","value":_vm.model.product,"resource":_vm.$data.$constants.RESOURCES.RESOURCE_PRODUCTS},on:{"input":function($event){return _vm.update('product', $event)}}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Proveedores","label-for":"providers"}},[_c('BaseSelect',{attrs:{"id":"providers","value":_vm.model.provider,"resource":_vm.$data.$constants.RESOURCES.RESOURCE_PROVIDERS},on:{"input":function($event){return _vm.update('provider', $event)}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }