<template>
  <div>
    <BaseTable
      ref="orders-products"
      :columns="columns"
      :totals-configuration="totalsConfiguration"
      :resource="$data.$constants.RESOURCES.RESOURCE_ORDERS_PRODUCTS"
      :extraResourceList="'list-products'"
      :resourceStore="'orderProducts'"
      :loading="loading"
      :filters="filters"
      :hidePrinterButton="true"
      :hideImportButton="true"
      :hideUploadButton="true"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
    >
      <template #cell(amount)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(total)="data">
        {{ data.value | numberToLocalString }} €
      </template>
    </BaseTable>
    <OrderProductsListFilters
      ref="order-products-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('orderProducts/setFilters', $event)"
    />
  </div>
</template>

<script>
import OrderProductsListFilters from "@/components/orders-products/filters/OrderProductsListFilters.vue";
import BaseTable from "@/components/ui/table/BaseTable.vue";
import { mapGetters } from "vuex";

export default {
  name: "OrdersProductsTable",
  components: {
    BaseTable,
    OrderProductsListFilters,
  },
  data() {
    return {
      filtersVisible: false,
      loading: false,
      totalsConfiguration: [
        { colspan: 7 },
        { label: "Unidades", key: "units", unit: "" },
        {colspan: 1},
        { label: "Total", key: "total", unit: "€" },
      ],
      columns: [
        {
          label: "SKU",
          key: "product_sku",
          sortable: false,
        },
        {
          label: "Producto",
          key: "product_name",
          sortable: true,
        },
        {
          label: "Descripción",
          key: "description",
          sortable: false,
        },
        {
          label: "Categoría",
          key: "product_category",
          sortable: false,
        },
        {
          label: "Pedido",
          key: "order_number",
          sortable: false,
        },
        {
          label: "Job",
          key: "job_name",
          sortable: true,
        },
        {
          label: "Proveedor",
          key: "order_provider",
          sortable: false,
        },
        {
          label: "unidades",
          key: "units",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "Precio unitario",
          key: "amount",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "TOTAL",
          key: "total",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filters: "orderProducts/getFilters",
    }),
  },
  methods: {
    handleClearListFilters() {
      this.$store.commit("orderProducts/setFilters", {});
      this.$refs["order-products-list-filters"].clearFilters();
    },
  },
};
</script>
