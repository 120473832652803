<template>
  <BaseCard
   :footer-visible="false"
   :caption-visible="false"
 >
   <template #title>
     <span class="h3 font-weight-bold mb-0 text-uppercase">
        Pedidos productos
     </span>
   </template>
   <OrdersProductsTable />
 </BaseCard>
</template>


<script>
import OrdersProductsTable from '@/components/orders-products/table/OrdersProductsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
 components: { 
  BaseCard, 
  OrdersProductsTable 
},
}
</script>
